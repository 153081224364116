@font-face {
    font-family: "Goldman Regular";
    src: url("https://xivintheshell.github.io/resources/fonts/Goldman/Goldman-Regular.ttf");
}
@font-face {
    font-family: "Goldman Bold";
    src: url("https://xivintheshell.github.io/resources/fonts/Goldman/Goldman-Bold.ttf");
}
@font-face {
    font-family: "Michroma";
    src: url("https://xivintheshell.github.io/resources/fonts/Michroma/Michroma-Regular.ttf");
}

/*======== sections ========*/

/* Stats display */

.buff {
    text-align: center;
    width: 54px;
    display: inline-block;
}
.buff.hidden {
    display: none;
}
.buff>img {
    display: inline-block;
    height: 40px;
    vertical-align: top;
}

/* Skills window */

.skillsWindow {
    line-height: 1;
}
.skillInfoText {
    margin-bottom: 10px;
}
.skillButton {
    display: inline-block;
    user-select: none;
    position: relative;
}

/* Danger button */
.dangerButton {
    color: #be0f0f;
    border: 1px solid #be0f0f;
    background: transparent;
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.dangerButton:hover {
    color: #ffffff;
    background: #be0f0f;
    text-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 12px rgba(190, 15, 15, 0.4);
}


/*======== individual elems ========*/

.clickable {
    display: inline-block;
}
.clickable:hover {
    cursor: pointer;
}

.paragraph {
    margin: 10px 0;
}